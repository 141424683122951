export function makeComboOptions<T, K extends keyof T, U extends keyof T>(
  arr: T[],
  id: K,
  title: U,
): { id: T[K]; title: T[U] }[] {
  return arr.map((el) => {
    return {
      id: el[id],
      title: el[title],
    };
  });
}

export function formatFio(fullName: string | null | undefined): string {
  if (!fullName) return "Не указано";
  return fullName
    .split(" ")
    .map((el, i) => (i === 0 ? el : `${el[0]}.`))
    .join(" ");
}

export function stripTags(text: string): string {
  const regex = /(<([^>]+)>)/gi;
  return text.replace(regex, "");
}

export function formatFileSize(size?: number): string {
  if (!size) return "0 б";
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(1)} ${["б", "Кб", "Мб", "Гб", "Гб"][i]}`;
}

export function downloadFileByUrl(url: string, downloadName: string): void {
  const link = document.createElement("a");
  link.href = url;
  link.rel = "noopener";
  link.target = "_blank";
  document.body.appendChild(link);
  link.setAttribute("download", downloadName);
  link.click();
  document.body.removeChild(link);
}

// https://stackoverflow.com/a/30810322/3054135
function fallbackCopyTextToClipboard(
  text: string,
  onSuccess?: (msg: string) => void,
  onError?: (msg: string) => void,
): void {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    if (!successful) {
      throw new Error("Ошибка копирования");
    }
    onSuccess && onSuccess("Текст успешно скопирован в буфер");
  } catch (err) {
    console.error(err);
    onError && onError(err as string);
  }
  document.body.removeChild(textArea);
}

export function copyTextToClipboard(
  text: string,
  onSuccess?: (msg: string) => void,
  onError?: (msg: string) => void,
): void {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      onSuccess && onSuccess("Текст успешно скопирован в буфер");
    },
    (err) => {
      console.error(err);
      onError && onError(err as string);
    },
  );
}
