import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
const uri = "https://tm.dev.rusatom.dedyn.io/graphql";
const httpLink = createHttpLink({ uri });
const httpUploadLink = createUploadLink({ uri: "https://bpms.dev.rusatom.dedyn.io/graphql" });
// @TODO - Разобраться после готовности авторизации на бэке
// const errorHandlers = {
//   TokenExpiredError: () => {
//     console.log("Token , ...logging out...");
//   },
//   InvalidTokenError: (message: string) => console.log(`[Token error]: Message: ${message}`),
//   default: (message: string, locations: string, path: string) => {
//     console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
//   },
// };
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // @TODO - Разобраться после готовности авторизации на бэке
    // graphQLErrors.map(({ message, locations, path, extensions }) =>
    //   (errorHandlers[extensions.code] || errorHandlers.default)({ message, locations, path }),
    // );
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});
const authLink = setContext((_, { headers }) => ({
  // headers: {
  //   authorization: `Bearer ${window.store.get("user@session")}`,
  //   ...headers,
  // },
}));

const apolloDefaultClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: { watchQuery: { fetchPolicy: "network-only" } },
});

export default apolloDefaultClient;

export const apolloUploadClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpUploadLink as never)),
  cache: new InMemoryCache(),
});
