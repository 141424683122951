// import library from '@pg/rir-ui/src/RirUI'
import library from "@/assets/ui/RirUI.umd.min";
import("@/assets/ui/RirUI.css");
export default {
  async install(Vue) {
    const { Components, Directives, Mixins } = library;
    Object.keys(Components).forEach((name) => {
      Vue.component(Components[name].name, Components[name]);
    });
    Object.entries(Directives).forEach((directive) => {
      Vue.use(directive);
    });
    // Object.values(Mixins).forEach((mixin) => {
    //   Vue.mixin(mixin);
    // });
  },
};
