














































import { mapState } from "vuex";

export default {
  name: "LayoutSidebar",
  data() {
    return {
      isOpen: false,
      menuItems: [
        {
          title: "Реестры",
          icon: "errand",
          route: "/",
        },
      ],
    };
  },
  computed: { ...mapState("sidebar", ["isSidebarOpen"]) },
};
