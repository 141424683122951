export const MONTH_DAY: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const MONTH_DAY_LEAP: number[] = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const MONTHS: string[] = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
export const SHORT_WEEK_DAYS: string[] = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вc"];
