import dayjs from "dayjs";

export const DISPLAY_DATE_FORMAT = "DD.MM.YYYY";
export const DISPLAY_DATE_FORMAT_WITH_TIME = "DD.MM.YYYY в HH:mm";

export type DateType = string | Date | number;
export const dateToDateString = (d: DateType): string =>
  d instanceof Date ? d.toLocaleDateString() : new Date(d).toLocaleDateString();
export function formatDateTimeForDisplay(
  value?: string,
  format: string = DISPLAY_DATE_FORMAT,
): string {
  return value
    ? `${dayjs(+value)
        .local()
        .format(format)}`
    : "-";
}

export function formatDateTimeForDatepicker(value?: string, defaultValue = ""): string {
  return value
    ? `${dayjs(+value)
        .local()
        .valueOf()}`
    : defaultValue;
}

import utc from "dayjs/plugin/utc";

import { MONTHS } from "./time.constants";
dayjs.extend(utc);

export type DateMaskFormat = "YYYY-MM-DD" | "YYYY-DD-MM";
export type FormatType = "unix" | "format" | "date";
type MapOfDateFormatResult = { [key: string]: string };

export const mapOfDateFormat = (
  year: number | string,
  month: number | string,
  day: number | string,
): MapOfDateFormatResult => ({
  "YYYY-MM-DD": `${year}-${pad(Number(month))}-${pad(Number(day))}`,
  "YYYY-DD-MM": `${year}-${pad(Number(day))}-${Number(pad(Number(month)))}`,
});

export const mapOfDate = (
  date: DateType,
  format: DateMaskFormat,
): { date: Date; format: string; unix: number } => {
  const { year, month, day } = getDateField(date);
  const value = new Date(`${year}-${month}-${day} 00:00:00`);
  return {
    unix: getTime(value),
    format: mapOfDateFormat(year, month, day)[format],
    date: value,
  };
};

export function getDateField(date: DateType): { day: number; year: number; month: number } {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth() + 1;
  const year = new Date(date).getFullYear();
  return {
    day,
    month,
    year,
  };
}

export function getStringDate(date: Date): string {
  const { day, month, year } = getDateField(date);
  return `${year}-${month}-${day} 00:00:00`;
}

export function isLeapYear(year: number): boolean {
  return new Date(year, 1, 29).getMonth() === 1;
}

export function getDate(date: DateType): number {
  return new Date(date).getDate();
}

export function getMonth(date: DateType): number {
  return new Date(date).getMonth();
}

export function getTime(date: DateType): number {
  return new Date(date).getTime();
}

export function getYear(date: DateType): number {
  return new Date(date).getFullYear();
}

export function getMonthNameByIndex(index: number): string {
  return MONTHS[index];
}

export function addDays(date: DateType, days: number): number {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.getTime();
}

export function addYears(date: DateType, count: number): Date {
  const d = new Date(date);
  return new Date(d.setFullYear(d.getFullYear() + count));
}

export function addMonth(date: DateType, count: number): Date {
  const d = new Date(date);
  return new Date(d.setMonth(d.getMonth() + count));
}

export function subtractDays(date: number, days: number): number {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result.getTime();
}

export function subtractYears(date: DateType, count: number): Date {
  const d = new Date(date);
  return new Date(d.setFullYear(d.getFullYear() - count));
}

export function subtractMonth(date: DateType, count: number): Date {
  const d = new Date(date);
  return new Date(d.setMonth(d.getMonth() - count));
}

export function getDatesBetweenToDates(startDate: number, stopDate: number): Date[] {
  const dateArray: Date[] = [];
  let currentDate = startDate;
  while (currentDate < stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export function paddingFirstDayInMonth(year: number, month: number): number {
  let day = new Date(year, month, 1).getDay();
  if (day === 0) {
    // день недели 0 (воскресенье) в европейской нумерации будет 7
    day = 7;
  }
  return day - 1;
}

export function isValidDate(date: DateType): boolean {
  const d = new Date(date);
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d.getTime())) {
      console.error("date is not valid");
    } else {
      console.error("date is valid");
    }
  } else {
    console.error("not a date");
  }
  return Boolean(Date.parse(`${date}`) > 0);
}

export function isNow(d: number, date: Date): boolean {
  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  return day === d && month === getMonth(date) && year === getYear(date);
}

export function getDaysInMonth(month: number, year: number): Date[] {
  const date = new Date(year, month, 1);
  const days: Date[] = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function pad(n: number): string | number {
  return n < 10 ? `0${n}` : n;
}

export function getDateWithFormat(value: Date, format: DateMaskFormat = "YYYY-MM-DD"): string {
  const date = value;
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  return mapOfDateFormat(year, month, day)[format];
}

export function paddingLastDayInMonth(year: number, month: number, day: number): number {
  let week = new Date(year, month, day).getDay();
  if (week === 0) {
    // день недели 0 (воскресенье) в европейской нумерации будет 7
    week = 7;
  }
  return 7 - week;
}
