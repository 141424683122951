import { MutationBaseOptions } from "apollo-client/core/watchQueryOptions";
import { DocumentNode } from "graphql";
import VueApollo from "vue-apollo";

import apolloDefaultClient, { apolloUploadClient } from "@/app/core/gQL/apollo-connection";
import { apolloClients } from "@/shared/enums/api";

interface GetApolloOther {
  variables?: {
    [key: string]: any;
  };
  context?: any;
}

const defaultOptions = {
  // See 'apollo' definition
  // For example: default query options
  $query: {
    loadingKey: "loading",
    fetchPolicy: "network-only",
  },
};

class ApolloClient {
  get apolloProvider() {
    return new VueApollo({
      defaultClient: apolloDefaultClient,
      clients: {
        [apolloClients.UPLOAD]: apolloUploadClient,
        [apolloClients.DEFAULT]: apolloDefaultClient,
      },
    });
  }

  public async get<T>(query: DocumentNode, options?: GetApolloOther): Promise<T> {
    const request = await this.apolloProvider.defaultClient.query<T>({
      query,
      ...options,
      fetchPolicy: "network-only",
    });
    const result = request.data;
    return result;
  }

  public async set<T, V>(
    mutation: DocumentNode,
    variables: V,
    refetchQueries?: MutationBaseOptions<T, V>["refetchQueries"],
    clientId?: apolloClients,
  ): Promise<T> {
    const request = await this.apolloProvider.clients[clientId || apolloClients.DEFAULT].mutate({
      mutation,
      refetchQueries,
      variables,
    });
    const result = <T>request.data;
    return result;
  }
}

const apClient = new ApolloClient();
export { apClient };
