import { RouteConfig } from "vue-router/types/router";

import RouterViewWrapper from "@/app/components/RouterViewWrapper.vue";
import { loadPage } from "@/shared/utils";
const routePrefix = "projects/project";
const routeTaskPrefix = "tasks/task";
export const projectsRoutes: RouteConfig[] = [
  {
    path: "/projects",
    name: "projects",
    component: loadPage("projects/index"),
  },
  {
    path: "/projects/new-project",
    name: "new-project",
    component: loadPage("projects/new-project"),
  },
  {
    path: "/projects/:id",
    component: RouterViewWrapper,
    children: [
      {
        path: "/",
        name: "index",
        component: loadPage(`${routePrefix}/index`),
      },
      {
        path: "tasks/:taskId",
        component: RouterViewWrapper,
        children: [
          {
            path: "/",
            name: "current-project-task",
            component: loadPage(`${routeTaskPrefix}/index`),
          },
          {
            path: "edit-task",
            name: "edit-project-task",
            component: loadPage(`${routeTaskPrefix}/edit-task`),
          },
        ],
      },
      {
        path: "project-result",
        name: "project-result",
        component: loadPage(`${routePrefix}/project-result`),
      },
      {
        path: "project-team",
        name: "project-team",
        component: loadPage(`${routePrefix}/project-team`),
      },
      {
        path: "project-comments",
        name: "project-comments",
        component: loadPage(`${routePrefix}/project-comments`),
      },
      {
        path: "edit",
        name: "edit-project",
        component: loadPage(`${routePrefix}/edit-project`),
      },
      {
        path: "new-project-task",
        name: "new-project-task",
        component: loadPage(`${routePrefix}/new-project-task`),
      },
    ],
  },
];
