<template>
  <div
    class="layout-main"
    :class="{ 'layout-main--close': !isSidebarOpen }"
  >
    <rir-app-layout
      :isToggle="true"
      @toggleMenu="toggleMenu"
    >
      <template v-slot:sidebar>
        <layout-sidebar />
      </template>
      <template v-slot:toolbar>
        <layout-toolbar />
      </template>
      <layout-content :style="`background-color: var(--rir-${color})`" />
    </rir-app-layout>
    <layout-right-tabs v-if="showTabs" />
  </div>
</template>

<script>
  import { mapMutations, mapState } from "vuex";

  import LayoutContent from "@/app/components/Layout/LayoutContent.vue";
  import LayoutSidebar from "@/app/components/Layout/LayoutSidebar.vue";
  import LayoutToolbar from "@/app/components/Layout/LayoutToolbar.vue";

  export default {
    name: "LayoutMain",
    components: {
      LayoutSidebar,
      LayoutToolbar,
      LayoutContent,
    },
    computed: {
      ...mapState("sidebar", ["isSidebarOpen"]),
      showTabs() {
        return !!(this.$route.name === "edit-registry" || this.$route.name === "edit-form");
      },
      color() {
        return this.$route.name === "apps" || this.$route.name === "task-manager" ? "rush" : "amelie";
      },
    },
    watch: {
      color(value) {
        const toolbar = document.querySelector(".rir-app-layout__toolbar");
        const body = document.querySelector(".rir-app-layout__body");
        toolbar.style.backgroundColor = `var(--rir-${value})`;
        body.style.backgroundColor = `var(--rir-${value})`;
      },
    },
    methods: {
      ...mapMutations("sidebar", ["setSidebarState"]),
      toggleMenu(isOpen) {
        this.setSidebarState(isOpen);
      },
    },
  };
</script>

<style lang="scss" scoped>
.layout-main {
  &::v-deep {

    .rir-app-layout__body {
      left: 344px;
      width: calc(100% - 344px);
    }

    .rir-app-layout__body::after {
      display: none;
    }

    .rir-app-layout__body::before {
      left: 344px;
    }

    .rir-app-layout__sidebar {
      width: 344px;
      padding-right: 0;
      transition: all 0.35s;
    }

    .rir-app-layout__close {
      position: fixed;
      left: 307px;

      &--open {
        left: 25px;
      }
    }
  }

  &--close {
    &::v-deep {
      .rir-app-layout__sidebar {
        z-index: 9;
        width: 60px;
        padding-right: 0;
      }

      .rir-app-layout__body {
        left: 60px;
        width: calc(100% - 60px);
      }

      .rir-app-layout__body::before {
        left: 60px;
      }
    }
  }
}
</style>
