import { computed, ref } from "@vue/composition-api";

const modals = ref<string[]>([]);
const openModals = ref<string[]>([]);

// eslint-disable-next-line no-return-assign
const addModal = (name: string) => (modals.value = [...modals.value, name]);

const removeModal = (name: string) => {
  const idx = modals.value.indexOf(name);
  if (idx === -1) {
    throw new Error("Impossible to remove non-existing modal");
  }
  modals.value.splice(idx, 1);
};

const showModal = ({ name, show }: { name: string; show: boolean }) => {
  // @NOTE: если модалька открывется через v-show + noPortal
  if (show) {
    openModals.value = [...openModals.value, name];
  }
  else {
    const idx = openModals.value.indexOf(name);
    if (idx === -1) {
      throw new Error("Impossible to remove non-existing modal");
    }
    openModals.value.splice(idx, 1);
  }
};

export const useModalsLayout = () => {
  return {
    modals: computed(() => modals.value),
    openModals: computed(() => openModals.value),
    addModal,
    removeModal,
    showModal,
  };
};
