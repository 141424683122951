import { TableColumns } from "@/app/components/Base/VTable/table.props";
import { Task, TaskSheetColumnCode } from "@/shared/types/schema.types";
import { formatDateTimeForDisplay, toShortname } from "@/shared/utils";

export enum TableDataIds {
  id = TaskSheetColumnCode.Id,
  name = TaskSheetColumnCode.Name,
  author = TaskSheetColumnCode.Author,
  createdAt = TaskSheetColumnCode.CreatedAt,
  executor = TaskSheetColumnCode.Executor,
  deadline = TaskSheetColumnCode.Deadline,
  $status = TaskSheetColumnCode.Status,
  $priority = TaskSheetColumnCode.Priority,
}

export type TableColumnDataId = keyof typeof TableDataIds | "$action" | "$settings";

export const taskTableColumns: TableColumns<Task, TableColumnDataId>[] = [
  {
    title: "",
    dataId: "$priority",
    customSlot: "priority",
    sorted: true,
  },
  {
    title: "ID",
    dataId: "id",
    format: ({ index }) => {
      // console.log(index);
      return index;
    },
    sorted: true,
  },
  {
    title: "Название",
    dataId: "name",
    format: ({ name }) => {
      return `${name}`;
    },
    sorted: true,
  },
  {
    title: "Постановщик",
    dataId: "author",
    deep: ["author"],
    format: ({ author }) => toShortname(author?.fullName || ""),
    empty: "-",
    sorted: true,
  },
  {
    title: "Создано",
    dataId: "createdAt",
    format: ({ createdAt }) => formatDateTimeForDisplay(createdAt || ""),
    sorted: true,
  },
  {
    title: "Исполнитель",
    dataId: "executor",
    format: ({ executor }) => toShortname(executor?.fullName || ""),
    empty: "-",
    sorted: true,
  },
  {
    title: "Срок",
    dataId: "deadline",
    format: ({ deadline }) => formatDateTimeForDisplay(deadline),
    sorted: true,
  },
  {
    title: "Статус",
    dataId: "$status",
    customSlot: "status",
    sorted: true,
  },
  {
    title: "",
    dataId: "$action",
    settings: true,
    customSlot: "actions",
  },
];
