import { RouteConfig } from "vue-router/types/router";

import { loadPage } from "@/shared/utils";

export const errorRoutes: RouteConfig[] = [
  {
 path: "/404",
component: loadPage("404/index"), 
},
  {
 path: "*",
redirect: "/404", 
},
];
