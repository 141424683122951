import Vue from "vue";

import app from "@/app/App.vue";
import underdog from "@/app/components/underdog/index";
import store from "@/app/core/store/index";

const vm = new Vue({
  extends: underdog,
  store: store,
  router: app.router,
});

vm.$mount();

document.documentElement.querySelector("body").append(vm.$el);
