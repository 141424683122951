export function getFilterQuery<T extends { [key: string]: unknown }>(
  filterData: T,
): Record<string, unknown> {
  return _.reduce(
    filterData,
    (acc, v, k) => {
      const hasEmptyKeys = _.isObject(v) ? Object.values(v).some((el) => el == null) : false;
      acc = {
        ...acc,
        [k]: !hasEmptyKeys && !_.isNil(v) ? v : null,
      };
      return acc;
    },
    {},
  );
}
