<template>
  <div class="layout-content">
    <router-view />
  </div>
</template>

<script>
  export default { name: "LayoutContent" };
</script>
<style lang="scss" scoped>
.layout-content {
  padding: 0 20px;
}
</style>
