// https://stackoverflow.com/a/61734080/3054135
import { inject, provide } from "@vue/composition-api";
import { CombinedVueInstance, Vue } from "vue/types/vue";

const ProviderSymbol = Symbol();

interface NotificationData {
  text: string;
  icon: string;
  type: "success" | "error";
}

export default function useRoot(): {
  provideRoot: (
    root: CombinedVueInstance<Vue, unknown, unknown, unknown, Record<never, unknown>>
  ) => void;
  injectRoot: () =>
    | (Record<never, unknown> & Vue & { $notification: (arg: NotificationData) => void })
    | undefined;
} {
  const provideRoot = (
    root: CombinedVueInstance<Vue, unknown, unknown, unknown, Record<never, unknown>>,
  ): void => provide(ProviderSymbol, root);

  const injectRoot = ():
    | (unknown & Record<never, unknown> & Vue & { $notification: (arg: NotificationData) => void })
    | undefined => inject(ProviderSymbol);

  return {
    provideRoot,
    injectRoot,
  };
}
