import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { errorRoutes } from "@/app/core/router/routes/error";
import { projectsRoutes } from "@/app/core/router/routes/projects.routes";
import { tasksRoutes } from "@/app/core/router/routes/tasks.routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [...errorRoutes, ...tasksRoutes, ...projectsRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
