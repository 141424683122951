import { DirectiveBinding } from "vue/types/options";

export const clickOutside = {
  bind(el: any, binding: DirectiveBinding): void {
    const bubble = binding.modifiers.bubble;
    const handler = (e: Event): void => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;

    document.addEventListener("click", handler);
  },

  unbind(el: any): void {
    document.removeEventListener("click", el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
};
