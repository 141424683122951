import "@/plugins/composition-api";
import "@/plugins/lodash";
import "@/plugins/dayjs";
import "@/plugins/autoregister";
import "@/plugins/portal";
import "@/plugins/underdog";

import RLib from "@pg/uikit";
import { ApolloClients } from "@vue/apollo-composable";
import CompositionApi, { provide } from "@vue/composition-api";
import _ from "lodash";
import Maska from "maska";
import Vue from "vue";
import VueApollo from "vue-apollo";
import VueTippy, { TippyComponent } from "vue-tippy";

import App from "@/app/App.vue";
import apolloClient, { apolloUploadClient } from "@/app/core/gQL/apollo-connection";
import router from "@/app/core/router";
import store from "@/app/core/store";
import { clickOutside } from "@/app/directives/clickOutside";
import RirLib from "@/plugins/RirLib";

Vue.component("tippy", TippyComponent);
Vue.use(VueTippy);

Vue.config.productionTip = false;
Vue.use(RirLib);
Vue.use(RLib);
Vue.use(VueApollo);

Vue.use(CompositionApi);
Vue.directive("clickOutside", clickOutside);
Vue.use(Maska);
Vue.mixin({
  computed: {
    _() {
      return _;
    },
  },
});

import TextHighlight from "vue-text-highlight";

import { apClient } from "@/app/core/gQL/apolloClient";
import { apolloClients } from "@/shared/enums/api";

Vue.component("text-highlight", TextHighlight);

export function iniApplication(): void {
  const app = new Vue({
    router,
    store,
    apolloProvider: apClient.apolloProvider,
    setup() {
      provide(ApolloClients, {
        [apolloClients.DEFAULT]: apolloClient,
        [apolloClients.UPLOAD]: apolloUploadClient,
      });
    },
    render: (h) => h(App),
  });
  app.$mount("#app");
}
