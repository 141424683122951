













import { defineComponent, nextTick, onMounted } from "@vue/composition-api";
import Vue from "vue";

import LayoutMain from "@/app/components/Layout/LayoutMain.vue";
import { useModalsLayout } from "@/shared/hooks/modal";
import useRoot from "@/shared/hooks/root/useRoot";

export default defineComponent({
  components: { LayoutMain },
  setup(_, cxt) {
    const { provideRoot } = useRoot();
    provideRoot(cxt.root);
    const { modals } = useModalsLayout();

    onMounted(async() => {
      await nextTick();
      Vue.prototype.$notification = (
        cxt.refs.notification as Vue & { pushNotification: () => void }
      ).pushNotification;
    });

    return { modals };
  },
});
