import RouterViewWrapper from "@/app/components/RouterViewWrapper.vue";
import { loadPage } from "@/shared/utils";
const routePrefix = "tasks/task";
export const tasksRoutes = [
  {
    path: "/",
    name: "tasks",
    component: loadPage("tasks/index"),
  },
  {
    path: "/new-task",
    name: "new-task",
    component: loadPage("tasks/new-task"),
  },
  {
    path: "/tasks/:taskId",
    component: RouterViewWrapper,
    children: [
      {
        path: "/",
        name: "current-task",
        component: loadPage(`${routePrefix}/index`),
      },
      {
        path: "edit-task",
        name: "edit-task",
        component: loadPage(`${routePrefix}/edit-task`),
      },
    ],
  },
];
