<template>
  <div class="layout-toolbar">
    <portal-target
      name="backLink"
      slim
    />
    <div class="profile__wrapper">

    </div>
  </div>
</template>

<script>
  export default { name: "LayoutToolbar" };
</script>

<style lang="scss" scoped>
  .layout-toolbar {
    display: flex;
    width: 100%;
  }

  .profile__wrapper {
    flex: 1;
    display: flex;
    justify-content: right;
  }
</style>
