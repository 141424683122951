import { StatusData } from "@/shared/types";

export const STATUSES_CONSTANTS: StatusData = {
  NEW: {
    name: "Новая",
    shortname: "Н",
    bgColor: "#81ABEE",
  },
  WIP: {
    name: "В работе",
    shortname: "Р",
    bgColor: "#BB9AF4",
  },
  CONCORD: {
    name: "Согласование",
    shortname: "С",
    bgColor: "#E9A35D",
  },
  DONE: {
    name: "Завершено",
    shortname: "З",
    bgColor: "#8DC95E",
  },
  ARCHIVE: {
    name: "Архив",
    shortname: "А",
    bgColor: "#A2ABBE",
  },
};
